import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404" />
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <section className="s404-section"></section>
  </>
)

export default NotFoundPage
